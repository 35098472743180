
import {default as mixins} from './mixins/user.js';

export default {
  name: 'user-name',
  mixins: [mixins],
  props: {
    user: {type: Object, required: true},
    owner: {type: Boolean, default: false},
  },
  computed: {
    tagName(): string {
      return this.user.deleted_at ? 'del' : 'a';
    },
  },
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  ref: "editor",
  class: "tag-editor dropup"
}
const _hoisted_2 = { class: "align-self-center mx-2 text-muted" }
const _hoisted_3 = {
  ref: "cloud",
  class: "tag-clouds"
}
const _hoisted_4 = ["onClick", "aria-label"]
const _hoisted_5 = {
  class: "btn btn-xs text-muted",
  type: "button",
  title: "Więcej..."
}
const _hoisted_6 = { class: "dropdown-menu p-2" }
const _hoisted_7 = { class: "tag-clouds" }
const _hoisted_8 = ["onClick", "aria-label"]
const _hoisted_9 = { class: "tag-clouds" }
const _hoisted_10 = { class: "neon-tag" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["value", "placeholder"]
const _hoisted_13 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _component_vue_dropdown = _resolveComponent("vue-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_vue_icon, { name: "tag" })
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.popularTags.slice(0, 3), (tag) => {
        return (_openBlock(), _createElementBlock("li", null, [
          _createElementVNode("a", {
            onClick: ($event: any) => ($options.toggleTag({ name: tag.name })),
            class: "suggest",
            "aria-label": tag.text,
            "data-balloon-pos": "up"
          }, [
            _createVNode(_component_vue_icon, { name: "tagPopularInclude" }),
            _createTextVNode(" " + _toDisplayString(tag.name), 1 /* TEXT */)
          ], 8 /* PROPS */, _hoisted_4)
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ], 512 /* NEED_PATCH */),
    ($props.popularTags.length > 3)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("button", _hoisted_5, [
            _createVNode(_component_vue_icon, { name: "tagPopularMore" })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ul", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.popularTags.slice(3), (tag) => {
                return (_openBlock(), _createElementBlock("li", null, [
                  _createElementVNode("a", {
                    onClick: ($event: any) => ($options.toggleTag({name: tag.name})),
                    class: "suggest",
                    "aria-label": tag.text,
                    "data-balloon-pos": "up"
                  }, [
                    _createVNode(_component_vue_icon, { name: "tagPopularInclude" }),
                    _createTextVNode(" " + _toDisplayString(tag.name), 1 /* TEXT */)
                  ], 8 /* PROPS */, _hoisted_8)
                ]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ])
          ])
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("ul", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tags, (tag) => {
        return (_openBlock(), _createElementBlock("li", null, [
          _createElementVNode("span", _hoisted_10, [
            _createTextVNode(_toDisplayString(tag.name) + " ", 1 /* TEXT */),
            _createElementVNode("a", {
              onClick: ($event: any) => ($options.toggleTag(tag)),
              class: "remove"
            }, [
              _createVNode(_component_vue_icon, { name: "tagRemove" })
            ], 8 /* PROPS */, _hoisted_11)
          ])
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _createElementVNode("input", {
      value: $data.searchText,
      placeholder: $props.placeholder,
      onInput: _cache[0] || (_cache[0] = ($event: any) => ($data.searchText = $event.target.value)),
      onKeyup: [
        _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => ($options.setTag && $options.setTag(...args)), ["space"])),
        _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.dropdown.hideDropdown && _ctx.dropdown.hideDropdown(...args)), ["esc"])),
        _cache[4] || (_cache[4] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.dropdown.goUp && _ctx.dropdown.goUp(...args)), ["prevent"]), ["up"])),
        _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.dropdown.goDown && _ctx.dropdown.goDown(...args)), ["prevent"]), ["down"]))
      ],
      onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($options.selectTag && $options.selectTag(...args)), ["enter"])),
      ref: "input",
      type: "text",
      tabindex: "4",
      autocomplete: "off",
      name: "tags"
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_12),
    _createVNode(_component_vue_dropdown, {
      items: $data.filteredTags,
      onSelect: $options.toggleTag,
      ref: "dropdown",
      class: "tag-dropdown mt-2 w-100"
    }, {
      item: _withCtx((slot) => [
        _createElementVNode("span", {
          class: "neon-tag",
          textContent: _toDisplayString(slot.item.name)
        }, null, 8 /* PROPS */, _hoisted_13),
        _createElementVNode("small", null, "×" + _toDisplayString(slot.item.topics + slot.item.microblogs + slot.item.jobs), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["items", "onSelect"])
  ], 512 /* NEED_PATCH */))
}
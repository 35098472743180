
export default {
  props: {
    items: {
      type: [Array, Object],
      default: [],
    },
    currentTab: null,
  },
};

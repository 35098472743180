import { withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["maxlength", "placeholder", "disabled", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_dropdown = _resolveComponent("vue-dropdown")!
  const _directive_autosize = _resolveDirective("autosize")!
  const _directive_paste = _resolveDirective("paste")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      class: "comment-form",
      maxlength: $props.maxLength + 16,
      placeholder: $props.placeholder,
      disabled: $props.disabled,
      rows: "1",
      value: $props.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.input && $options.input(...args))),
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => ($options.save && $options.save(...args)), ["ctrl"]), ["enter"])),
        _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => ($options.save && $options.save(...args)), ["meta"]), ["enter"])),
        _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => ($options.cancel && $options.cancel(...args)), ["esc"])),
        _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.onKeyDown && $options.onKeyDown(...args)))
      ],
      onKeyup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.onKeyUp && $options.onKeyUp(...args))),
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.onKeyUp && $options.onKeyUp(...args))),
      ref: "textarea"
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), [
      [_directive_autosize],
      [_directive_paste, $props.allowPaste ? $options.paste : null, "success"]
    ]),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_vue_dropdown, {
      ref: "dropdown",
      items: $data.items,
      onSelect: $options.selectItem,
      rect: $data.rect
    }, null, 8 /* PROPS */, ["items", "onSelect", "rect"])
  ]))
}
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "user-avatar default-avatar"
}
const _hoisted_4 = {
  viewBox: "0 0 72 72",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_5 = {
  x: "50%",
  y: "50%",
  "dominant-baseline": "central",
  "text-anchor": "middle",
  fill: "currentColor"
}
const _hoisted_6 = {
  key: 2,
  class: "is-online"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.hasAvatar)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $props.photo,
          alt: $props.name,
          class: "d-block user-avatar",
          loading: "lazy",
          style: {width:'100%','height':'100%','object-fit':'cover','object-position':'center'}
        }, null, 8 /* PROPS */, _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createElementBlock("svg", _hoisted_4, [
            _createElementVNode("text", _hoisted_5, _toDisplayString($props.initials), 1 /* TEXT */)
          ]))
        ])),
    ($props.isOnline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("v-if", true)
  ]))
}
import { resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_profile = _resolveDirective("profile")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent($options.tagName), {
    class: _normalizeClass(["username", $props.owner ? 'neon-username-author': 'neon-color-link']),
    style: _normalizeStyle({textDecoration: this.user.is_blocked ? 'line-through' : ''}),
    textContent: _toDisplayString($props.user.name)
  }, null, 8 /* PROPS */, ["class", "style", "textContent"])), [
    [_directive_profile, $props.user.id]
  ])
}